<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";

const { formatDateUtcD, formatDateIsoD, parseDateIso, parseDateInput } = useDateTimeFormatter();
const emits = defineEmits(["input"]);

const props = defineProps({
  label: {
    type: String,
  },
  value: {
    type: String,
  },
  min: {
    type: Date,
    default: () => new Date(1900, 0, 1),
  },
  max: {
    type: Date,
    default: () => new Date(2050, 11, 31),
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

const dateMenu = ref(false);

const dateVal = ref(null);
const dateFormatted = ref("");

const minDate = computed(() => formatDateIsoD(props.min));
const maxDate = computed(() => formatDateIsoD(props.max));

watch(
  () => props.value,
  () => {
    //console.log("DateInput, watch, value:", props.value);
    const dt = formatDateIsoD(props.value);
    dateVal.value = dt;
    //console.log("DateInput, watch, value:", dateVal.value);
  }
);

//todo wat moet dit zijn in vue3??
// $_veeValidate: {
//     // fetch the current value from the innerValue defined in the component data.
//     value() {
//       return dateVal.value;
//     }
//   }

watch(
  () => dateVal.value,
  () => {
    //console.log("DateInput, watch, dateVal: ", dateVal.value);
    if (dateVal.value !== null && dateVal.value !== undefined && dateVal.value !== "") {
      const dt = parseDateIso(dateVal.value);
      //console.log("DateInput, watch, dateVal, parseDateIso: ", dt);

      dateFormatted.value = formatDateUtcD(dt);
      emits("input", formatDateIsoD(dt));
    } else {
      //console.log("DateInput, watch, dateVal, set dateFormatted ");
      dateFormatted.value = "";
      emits("input", null);
    }
    // },
    // {
    //   immediate: true,
  }
);

function blurEventHandler(e) {
  const val = e.target.value;
  //console.log("DateInput, blurEventHandler: ", val, dateFormatted.value, e);

  if (val !== null && val !== undefined && val !== "") {
    const newDate = parseDateInput(val);
    //console.log("DateInput, blurEventHandler, isValidDate: ", newDate, newDate.isValid(), newDate.value);
    if (newDate.isValid()) {
      dateVal.value = formatDateIsoD(newDate);
      emits("input", dateVal.value);
    } else {
      dateVal.value = null;
    }
  } else {
    //console.log("DateInput, blurEventHandler, val is empty string");
    dateVal.value = null;
    emits("input", null);
  }
}
</script>

<template>
  <v-menu v-model="dateMenu" :disabled="props.readonly" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        :outlined="outlined && !props.readonly"
        :class="{ noBorders: props.readonly }"
        hide-details="auto"
        persistent-placeholder
        v-model="dateFormatted"
        :label="label"
        :error-messages="props.errors"
        :readonly="props.readonly"
        v-on="on"
        @blur="blurEventHandler($event)"
        v-on:keyup.enter="$event.target.blur()"
      ></v-text-field>
    </template>
    <v-date-picker :min="minDate" :max="maxDate" v-model="dateVal" no-title @input="dateMenu = false"></v-date-picker>
  </v-menu>
</template>
