var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-measures-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "app-welcome-message" },
            [
              _c("h1", [_vm._v("Bulk maatregelen wijzigen")]),
              _c("p", [
                _vm._v(
                  "Meerdere maatregelen kunnen gelijktijdig aangepast worden."
                ),
              ]),
              _setup.appliedCount >= 1000
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        text: "",
                        outlined: "",
                        type: "warning",
                        icon: "mdi-alert",
                      },
                    },
                    [_vm._v("Aantal is beperkt tot 1000")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper",
        {
          attrs: { flat: "" },
          model: {
            value: _setup.currentStep,
            callback: function ($$v) {
              _setup.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 1, step: "1" } },
                [_vm._v(" Selectie maatregelen ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 2, step: "2" } },
                [_vm._v(" Wijzigingen ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 3, step: "3" } },
                [_vm._v(" Bevestiging ")]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                { attrs: { complete: _setup.currentStep > 4, step: "4" } },
                [_vm._v(" Resultaat ")]
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "1" } },
                [
                  _c("h3", [
                    _vm._v(
                      "Selecteer de maatregelen welke gewijzigd moeten worden"
                    ),
                  ]),
                  _setup.token
                    ? _c("applied-overview-grid", {
                        attrs: {
                          token: _setup.token,
                          "hide-invest": "",
                          "hide-edit": "",
                          "show-select": "",
                        },
                        on: {
                          "changed-selection": _setup.changedSelection,
                          "fetch-data-complete": _setup.appliedLoaded,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    disabled:
                                      _setup.selectedApplied.length >= 2,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              { staticClass: "d-inline-block" },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "primary",
                                                  attrs: {
                                                    disabled:
                                                      _setup.selectedApplied
                                                        .length < 2,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _setup.currentStep = 2
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Volgende stap")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_vm._v(" Selecteer meerdere maatregelen ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    to: { name: "AppliedOverview" },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "2" } },
                [
                  _c(
                    _setup.ValidationObserver,
                    { ref: "obs" },
                    [
                      _c("h3", [
                        _vm._v(
                          "Selecteer welke wijzigingen gemaakt moeten worden"
                        ),
                      ]),
                      _c("br"),
                      _c(
                        "v-form",
                        {
                          staticClass: "inner",
                          attrs: { id: "update-applied-form" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onUpdate.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label: "Status",
                                    },
                                    model: {
                                      value: _setup.request.changeStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "changeStatus",
                                          $$v
                                        )
                                      },
                                      expression: "request.changeStatus",
                                    },
                                  }),
                                  _c("v-select", {
                                    staticClass: "pt-1",
                                    attrs: {
                                      items: _setup.statusses,
                                      "item-text": "descr",
                                      "item-value": "key",
                                      label: "Selecteer actuele status",
                                      "return-object": "",
                                      disabled: _setup.readonly,
                                      outlined: "",
                                      "single-line": "",
                                      "hide-details": "",
                                      required: "",
                                      rules: [(v) => !!v || "Selecteer status"],
                                    },
                                    model: {
                                      value: _setup.request.status,
                                      callback: function ($$v) {
                                        _vm.$set(_setup.request, "status", $$v)
                                      },
                                      expression: "request.status",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label: "Toelichting",
                                    },
                                    model: {
                                      value: _setup.request.changeDescription,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "changeDescription",
                                          $$v
                                        )
                                      },
                                      expression: "request.changeDescription",
                                    },
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      solo: "",
                                      rows: "2",
                                      outlined: "",
                                      name: "description",
                                      disabled: _setup.readonly,
                                      label: "",
                                    },
                                    model: {
                                      value: _setup.request.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "request.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label:
                                        "Informatieplicht verklaring voor RVO (Let op! Dit veld wordt gebruikt in de upload naar het RVO.)",
                                    },
                                    model: {
                                      value:
                                        _setup.request.changeExtDescription,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "changeExtDescription",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "request.changeExtDescription",
                                    },
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      solo: "",
                                      rows: "2",
                                      outlined: "",
                                      name: "extDescription",
                                      disabled: _setup.readonly,
                                      label: "",
                                    },
                                    model: {
                                      value: _setup.request.extDescription,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "extDescription",
                                          $$v
                                        )
                                      },
                                      expression: "request.extDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label: "Voorsteldatum",
                                    },
                                    model: {
                                      value: _setup.request.changeProposalDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "changeProposalDate",
                                          $$v
                                        )
                                      },
                                      expression: "request.changeProposalDate",
                                    },
                                  }),
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Datum",
                                      rules: "",
                                      mode: _setup.checkDateValid,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(_setup.DateInput, {
                                              attrs: {
                                                outlined: "",
                                                "persistent-placeholder": "",
                                                errors: errors,
                                              },
                                              model: {
                                                value:
                                                  _setup.request.proposalDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _setup.request,
                                                    "proposalDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "request.proposalDate",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label: "Datum ingepland",
                                    },
                                    model: {
                                      value: _setup.request.changePlannedDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "changePlannedDate",
                                          $$v
                                        )
                                      },
                                      expression: "request.changePlannedDate",
                                    },
                                  }),
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Datum",
                                      rules: "",
                                      mode: _setup.checkDateValid,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(_setup.DateInput, {
                                              attrs: {
                                                outlined: "",
                                                "persistent-placeholder": "",
                                                errors: errors,
                                              },
                                              model: {
                                                value:
                                                  _setup.request.plannedDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _setup.request,
                                                    "plannedDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "request.plannedDate",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label: "Datum uitgevoerd",
                                    },
                                    model: {
                                      value: _setup.request.changeAchievedDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _setup.request,
                                          "changeAchievedDate",
                                          $$v
                                        )
                                      },
                                      expression: "request.changeAchievedDate",
                                    },
                                  }),
                                  _c(_setup.ValidationProvider, {
                                    attrs: {
                                      name: "Datum",
                                      rules: "",
                                      mode: _setup.checkDateValid,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(_setup.DateInput, {
                                              attrs: {
                                                outlined: "",
                                                "persistent-placeholder": "",
                                                errors: errors,
                                              },
                                              model: {
                                                value:
                                                  _setup.request.achievedDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _setup.request,
                                                    "achievedDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "request.achievedDate",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: !_setup.changeAny },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 3
                                    },
                                  },
                                },
                                [_vm._v(" Volgende stap")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 1
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    to: { name: "AppliedOverview" },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "3" } },
                [
                  _c("h3", [_vm._v("Bevestiging")]),
                  _c("br"),
                  _vm._v(" Voor "),
                  _c("strong", [_vm._v(_vm._s(_setup.selectedApplied.length))]),
                  _vm._v(
                    " maatregelen zullen de volgende wijzigingen worden toegepast: "
                  ),
                  _c(
                    "v-list",
                    [
                      _setup.request.changeStatus && _setup.request.status
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "- status wordt gewijzigd naar: '" +
                                        _vm._s(_setup.request.status.descr) +
                                        "'"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.request.changeDescription
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "- toelichting wordt gewijzigd naar: '" +
                                        _vm._s(_setup.request.description) +
                                        "'"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.request.changeExtDescription
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "- informatieplicht verklaring wordt gewijzigd naar: '" +
                                        _vm._s(_setup.request.extDescription) +
                                        "'"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.request.changeProposalDate
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "- voorsteldatum wordt gewijzigd naar: '" +
                                        _vm._s(
                                          _setup.formatDateUtc(
                                            _setup.request.proposalDate
                                          )
                                        ) +
                                        "'"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.request.changePlannedDate
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "- datum ingepland wordt gewijzigd naar: '" +
                                        _vm._s(
                                          _setup.formatDateUtc(
                                            _setup.request.plannedDate
                                          )
                                        ) +
                                        "'"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.request.changeAchievedDate
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      "- datum uitgevoerd wordt gewijzigd naar: '" +
                                        _vm._s(
                                          _setup.formatDateUtc(
                                            _setup.request.achievedDate
                                          )
                                        ) +
                                        "'"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.applyBusy },
                                  on: { click: _setup.applyMutations },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Toepassen "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 2
                                    },
                                  },
                                },
                                [_vm._v(" Vorige stap ")]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    to: { name: "AppliedOverview" },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-cancel")]
                                  ),
                                  _vm._v(" Annuleren "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { staticClass: "pa-0", attrs: { step: "4" } },
                [
                  _c("h3", [_vm._v("Resultaat")]),
                  _c("br"),
                  _vm._v(" Wijzigingen voor "),
                  _c("strong", [_vm._v(_vm._s(_setup.selectedApplied.length))]),
                  _vm._v(" maatregelen "),
                  _setup.applyBusy
                    ? _c("span", { staticClass: "loadingDots" }, [
                        _vm._v("worden toegepast "),
                      ])
                    : _c("span", [_vm._v("zijn toegepast.")]),
                  !_setup.applyBusy && _setup.appliedResult
                    ? _c(
                        "v-list",
                        _vm._l(_setup.appliedResult, function (item) {
                          return _c(
                            "v-list-item",
                            { key: item.code },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  item.code == "Success"
                                    ? _c("v-list-item-title", [
                                        _vm._v(
                                          "- aantal succesvol gewijzigd: " +
                                            _vm._s(item.message)
                                        ),
                                      ])
                                    : item.code == "NoContent"
                                    ? _c("v-list-item-title", [
                                        _vm._v(
                                          "- aantal zonder wijzigingen: " +
                                            _vm._s(item.message)
                                        ),
                                      ])
                                    : item.code == "Error"
                                    ? _c("v-list-item-title", [
                                        _vm._v("- " + _vm._s(item.message)),
                                      ])
                                    : _c("v-list-item-title", [
                                        _vm._v(
                                          "- " +
                                            _vm._s(item.code) +
                                            " : " +
                                            _vm._s(item.message)
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: {
                                    disabled: _setup.applyBusy,
                                    to: { name: "AppliedOverview" },
                                  },
                                },
                                [_vm._v(" Klaar ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _setup.currentStep = 3
                                    },
                                  },
                                },
                                [_vm._v(" Vorige ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }