<script lang="ts" setup>
import { ref, getCurrentInstance, onMounted, computed, watch } from "vue";
//import { getAppliedFilterToken } from "@/services/api/token.api";
import { getAllAppliedStatusses, patchAppliedBulk, severityCode } from "@/services/api/measure.api";
import { FilterTokenName } from "@/stores/filtertoken";
import handleFilterTokens27 from "@/composables/handleFilterTokens27";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DateInput from "@/components/general/DateInput.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";

const { formatDateUtc } = useDateTimeFormatter();
//neem token over van maatregelen
const { token } = handleFilterTokens27(FilterTokenName.Applied);

const errored = ref(false);
const statussesLoaded = ref(false);
const readonly = ref(false);
const applyBusy = ref(false);
const request = ref({
  status: undefined,
  description: undefined,
  extDescription: undefined,
  proposalDate: undefined,
  plannedDate: undefined,
  achievedDate: undefined,

  changeStatus: false,
  changeDescription: false,
  changeExtDescription: false,
  changeProposalDate: false,
  changePlannedDate: false,
  changeAchievedDate: false,
});
const currentStep = ref(1);
const statusses = ref([]);
const selectedApplied = ref([]);
const appliedCount = ref(0);
const appliedResult = ref(undefined);

onMounted(() => {
  getStatusses();
});

const changeAny = computed(() => {
  return (
    (request.value.changeStatus && request.value.status) ||
    request.value.changeDescription ||
    request.value.changeExtDescription ||
    request.value.changeProposalDate ||
    request.value.changePlannedDate ||
    request.value.changeAchievedDate
  );
});

watch(
  () => request.value.status,
  () => {
    if (!request.value.changeStatus) {
      request.value.changeStatus = request.value.status !== "None";
    }
  }
);

watch(
  () => request.value.description,
  () => {
    if (!request.value.changeDescription) {
      request.value.changeDescription = request.value.description !== "";
    }
  }
);
watch(
  () => request.value.extDescription,
  () => {
    if (!request.value.changeExtDescription) {
      request.value.changeExtDescription = request.value.extDescription !== "";
    }
  }
);
watch(
  () => request.value.proposalDate,
  () => {
    if (!request.value.changeProposalDate) {
      request.value.changeProposalDate = !!request.value.proposalDate;
    }
  }
);
watch(
  () => request.value.plannedDate,
  () => {
    if (!request.value.changePlannedDate) {
      request.value.changePlannedDate = !!request.value.plannedDate;
    }
  }
);
watch(
  () => request.value.achievedDate,
  () => {
    if (!request.value.changeAchievedDate) {
      request.value.changeAchievedDate = !!request.value.achievedDate;
    }
  }
);

// async function handleFiltersChanged(filterData) {
//   //console.log("Buildingoverview handleFiltersChanged filterData: ", filterData);
//   if (filterData.length > 0) {
//     const getFilterToken = getAppliedFilterToken(searchToken.value, filterData);
//     const success = await createFilterToken(getFilterToken);
//     if (!success) {
//       proxy.$toaster.error("Ophalen van de filter token is mislukt!");
//     }
//   } else {
//     resetFilterToken();
//   }
// }

async function getStatusses() {
  // statusses t.b.v. dropdown
  const statussesResponse = await getAllAppliedStatusses();

  if (statussesResponse && !statussesResponse.error) {
    statusses.value = statussesResponse.keyDescList;
    //console.log("appliedDetails, getStatusses: ", statusses.value);
    statussesLoaded.value = true;
  } else {
    errored.value = true;
  }
}

function checkDateValid() {
  return { on: ["input"] };
}

function changedSelection(payload) {
  //console.log("AppliedMutation selected ", payload);
  selectedApplied.value = [...payload.selected];
}

function appliedLoaded(payload) {
  appliedCount.value = payload.count;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function applyMutations() {
  applyBusy.value = true;
  currentStep.value = 4;
  request.value.selection = [...selectedApplied.value];

  var response = await patchAppliedBulk(request.value);
  console.log("AppliedMutation applyMutations ", response);

  if (response.severity <= severityCode.warning) {
    appliedResult.value = [...response.data.results];
  } else {
    appliedResult.value = [{ code: "Error", message: "Probleem met muteren maatregelen => " + response.message }];
    proxy.$toaster.error(`Probleem met muteren maatregelen: ${response.message}`);
  }
  // console.log("AppliedMutation applyMutations ", appliedResult.value);
  applyBusy.value = false;
}
</script>

<template>
  <v-container fluid class="app-measures-overview">
    <v-row>
      <v-col class="app-welcome-message">
        <h1>Bulk maatregelen wijzigen</h1>
        <p>Meerdere maatregelen kunnen gelijktijdig aangepast worden.</p>
        <v-alert v-if="appliedCount >= 1000" dense text outlined type="warning" icon="mdi-alert">Aantal is beperkt tot 1000</v-alert>
      </v-col>
    </v-row>

    <v-stepper v-model="currentStep" flat>
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1"> Selectie maatregelen </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 2" step="2"> Wijzigingen </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 3" step="3"> Bevestiging </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="currentStep > 4" step="4"> Resultaat </v-stepper-step>
      </v-stepper-header>
      <br />
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <h3>Selecteer de maatregelen welke gewijzigd moeten worden</h3>
          <applied-overview-grid v-if="token" :token="token" hide-invest hide-edit show-select @changed-selection="changedSelection" @fetch-data-complete="appliedLoaded"></applied-overview-grid>

          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-tooltip bottom :disabled="selectedApplied.length >= 2">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="d-inline-block">
                      <v-btn :disabled="selectedApplied.length < 2" class="primary" @click="currentStep = 2"> Volgende stap</v-btn>
                    </div>
                  </template>
                  Selecteer meerdere maatregelen
                </v-tooltip>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="{ name: 'AppliedOverview' }">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0">
          <ValidationObserver ref="obs">
            <h3>Selecteer welke wijzigingen gemaakt moeten worden</h3>
            <br />
            <v-form id="update-applied-form" @submit.prevent="onUpdate" class="inner">
              <v-row class="form-group">
                <v-col cols="4">
                  <v-checkbox class="ma-0 pa-0" hide-details label="Status" v-model="request.changeStatus"></v-checkbox>
                  <v-select
                    class="pt-1"
                    v-model="request.status"
                    :items="statusses"
                    item-text="descr"
                    item-value="key"
                    label="Selecteer actuele status"
                    return-object
                    :disabled="readonly"
                    outlined
                    single-line
                    hide-details
                    required
                    :rules="[(v) => !!v || 'Selecteer status']"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox class="ma-0 pa-0" hide-details label="Toelichting" v-model="request.changeDescription"></v-checkbox>
                  <v-textarea solo rows="2" outlined v-model="request.description" name="description" :disabled="readonly" label=""></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    class="ma-0 pa-0"
                    hide-details
                    label="Informatieplicht verklaring voor RVO (Let op! Dit veld wordt gebruikt in de upload naar het RVO.)"
                    v-model="request.changeExtDescription"
                  ></v-checkbox>
                  <v-textarea solo rows="2" outlined v-model="request.extDescription" name="extDescription" :disabled="readonly" label=""></v-textarea>
                </v-col>
              </v-row>

              <v-row class="form-group">
                <v-col cols="4">
                  <v-checkbox class="ma-0 pa-0" hide-details label="Voorsteldatum" v-model="request.changeProposalDate"></v-checkbox>
                  <ValidationProvider name="Datum" rules="" v-slot="{ errors }" :mode="checkDateValid">
                    <date-input v-model="request.proposalDate" outlined persistent-placeholder :errors="errors"></date-input>
                  </ValidationProvider>
                </v-col>
                <v-col cols="4">
                  <v-checkbox class="ma-0 pa-0" hide-details label="Datum ingepland" v-model="request.changePlannedDate"></v-checkbox>

                  <ValidationProvider name="Datum" rules="" v-slot="{ errors }" :mode="checkDateValid">
                    <date-input v-model="request.plannedDate" outlined persistent-placeholder :errors="errors"></date-input>
                  </ValidationProvider>
                </v-col>
                <v-col cols="4">
                  <v-checkbox class="ma-0 pa-0" hide-details label="Datum uitgevoerd" v-model="request.changeAchievedDate"></v-checkbox>

                  <ValidationProvider name="Datum" rules="" v-slot="{ errors }" :mode="checkDateValid">
                    <date-input v-model="request.achievedDate" outlined persistent-placeholder :errors="errors"></date-input>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="!changeAny" class="primary" @click="currentStep = 3"> Volgende stap</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 1"> Vorige stap </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="{ name: 'AppliedOverview' }">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0">
          <h3>Bevestiging</h3>
          <br />
          Voor
          <strong>{{ selectedApplied.length }}</strong>
          maatregelen zullen de volgende wijzigingen worden toegepast:
          <v-list>
            <v-list-item v-if="request.changeStatus && request.status">
              <v-list-item-content>
                <v-list-item-title>- status wordt gewijzigd naar: '{{ request.status.descr }}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="request.changeDescription">
              <v-list-item-content>
                <v-list-item-title>- toelichting wordt gewijzigd naar: '{{ request.description }}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="request.changeExtDescription">
              <v-list-item-content>
                <v-list-item-title>- informatieplicht verklaring wordt gewijzigd naar: '{{ request.extDescription }}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="request.changeProposalDate">
              <v-list-item-content>
                <v-list-item-title>- voorsteldatum wordt gewijzigd naar: '{{ formatDateUtc(request.proposalDate) }}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="request.changePlannedDate">
              <v-list-item-content>
                <v-list-item-title>- datum ingepland wordt gewijzigd naar: '{{ formatDateUtc(request.plannedDate) }}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="request.changeAchievedDate">
              <v-list-item-content>
                <v-list-item-title>- datum uitgevoerd wordt gewijzigd naar: '{{ formatDateUtc(request.achievedDate) }}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="applyBusy" class="primary" @click="applyMutations">
                  <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                  Toepassen
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 2"> Vorige stap </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text :to="{ name: 'AppliedOverview' }">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="4" class="pa-0">
          <h3>Resultaat</h3>
          <br />
          Wijzigingen voor <strong>{{ selectedApplied.length }}</strong> maatregelen
          <span v-if="applyBusy" class="loadingDots">worden toegepast </span>
          <span v-else>zijn toegepast.</span>
          <v-list v-if="!applyBusy && appliedResult">
            <v-list-item v-for="item in appliedResult" v-bind:key="item.code">
              <v-list-item-content>
                <v-list-item-title v-if="item.code == 'Success'">- aantal succesvol gewijzigd: {{ item.message }}</v-list-item-title>
                <v-list-item-title v-else-if="item.code == 'NoContent'">- aantal zonder wijzigingen: {{ item.message }}</v-list-item-title>
                <v-list-item-title v-else-if="item.code == 'Error'">- {{ item.message }}</v-list-item-title>
                <v-list-item-title v-else>- {{ item.code }} : {{ item.message }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-container fluid>
            <v-row>
              <v-col cols="auto" class="form-group">
                <v-btn :disabled="applyBusy" class="primary" :to="{ name: 'AppliedOverview' }"> Klaar </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text @click="currentStep = 3"> Vorige </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
