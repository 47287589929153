var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        disabled: _setup.props.readonly,
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "max-width": "290px",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    class: { noBorders: _setup.props.readonly },
                    attrs: {
                      outlined: _vm.outlined && !_setup.props.readonly,
                      "hide-details": "auto",
                      "persistent-placeholder": "",
                      label: _vm.label,
                      "error-messages": _setup.props.errors,
                      readonly: _setup.props.readonly,
                    },
                    on: {
                      blur: function ($event) {
                        return _setup.blurEventHandler($event)
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return $event.target.blur()
                      },
                    },
                    model: {
                      value: _setup.dateFormatted,
                      callback: function ($$v) {
                        _setup.dateFormatted = $$v
                      },
                      expression: "dateFormatted",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _setup.dateMenu,
        callback: function ($$v) {
          _setup.dateMenu = $$v
        },
        expression: "dateMenu",
      },
    },
    [
      _c("v-date-picker", {
        attrs: { min: _setup.minDate, max: _setup.maxDate, "no-title": "" },
        on: {
          input: function ($event) {
            _setup.dateMenu = false
          },
        },
        model: {
          value: _setup.dateVal,
          callback: function ($$v) {
            _setup.dateVal = $$v
          },
          expression: "dateVal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }